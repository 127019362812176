body {
  background-color: color("white");
  color: color("ink");
  overflow-x: hidden;
}

a {
  color: color('primary');
  text-decoration: none;
}

a:active {
  border: none;
  border-color: white;
}
