.usa-input,
.usa-textarea {
  @extend %block-input-general;
  @extend %block-input-styles;
}

.usa-textarea {
  height: units("card");
}

.usa-input--error {
  @include u-border($theme-input-state-border-width, "error-dark");
}
