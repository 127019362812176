body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus {
  outline: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.usa-prose>p {
  max-width: 100% !important;
}

.usa-prose>ul li {
  max-width: 100% !important;
}

.usa-prose>ol li {
  max-width: 100% !important;
}

.usa-section-close {
  padding-top: 5px !important;
}


.usa-section--dark>a {
  color: #b50909 !important;
  text-decoration: none !important;
}

.usa-prose>a {
  color: #b50909 !important;
  text-decoration: none !important;
}

/* New styles for Shoelace */


.menu-nav::part(base) {
  background: var(--sl-color-neutral-0);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: none;
  border-bottom-width: 5px;
  border-bottom-color: white;
  border-bottom-style: solid;
}

.menu-nav::part(base):hover {
  color: #b50909;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: #b50909;
}

.menu-nav::part(base):focus-visible {
  background-color: blue;
  box-shadow: none;
}

.menu-nav::part(label) {
  color: rgb(86, 92, 101);
  font-weight: 700;
}

.menu-nav-link {
  color: rgb(86, 92, 101);
  font-weight: 400;
}

/* media queries to control header text */

@media (max-width: 480px) {
  .full-heading {
    display: none;
    font-size: 1.5rem !important;
  }
}

@media (min-width: 480px) {
  .small-heading {
    display: none;
    padding-bottom: 10px;
  }
}

a:focus,
input:focus {
  outline: none !important;
}


.map-container {
  height: 600px;
  margin: 20px;
}

/* modal forms */

.modal {
  background: #fff;
  border: #b40808;
  border-radius: 5px;
  margin-left: -200px;
  text-align: center;
  position: fixed;
  left: 45%;
  top: 20%;
  z-index: 11;
  width: 500px;
  min-height: 250px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  outline: none !important;
  pointer-events: auto;
}

;

.modal-dialog-additional {
  outline: none;
}

.close {
  float: right;
  margin-right: 13px;
  margin-top: 12px;
  border-width: 0px;
  font-size: 24pt;
  font-weight: bolder;
  background: white;
  outline: none;
}

.modal-header-additional {
  padding-left: 12px;
}


.modal-body {
  border-top: 3px solid #b40808;
  display: inline-block;
  padding-top: 12px;
  width: 100%;
}

.modal-open {
  pointer-events: none;
}

.modal-content {
  pointer-events: auto;
  border: #dddddd solid 1px;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  background-color: #000;
  opacity: 0.5;
  z-index: 10;
}

.PhoneInputInput {
  border-color: white;
  border-top: 0;
  border-left: 0;
  box-shadow: none;
}

/* Amplify Auth UI Hacks */

:root {
  --amplify-primary-color: #b40808;

  --amplify-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  --amplify-background-color: #efefef;

  --amplify-secondary-tint: #b40808;
  /* --container-height: 0; */
  /* --container-display: unset; */
}


amplify-authenticator {
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  height: 550px;
}

/* Badge template */

.badge-container {
  width: 322px;
  max-height: 243px;
  min-height: 243px;
  margin: 3px 0px 3px 6px;
  padding: 10px;
  display: inline-block;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
}

.container {
  color: #555555;
  ;
}

.badge-heading {
  font-size: 10pt;
  font-style: italic;
  text-align: center;
}

.badge-sub-heading {
  margin-top: -10px;
  min-height: 40px;
}

.badge-exhibitor {
  margin-top: 16px;
  display: inline-block;
  float: left;
}

.block-exhibitor {
  min-width: 40px;

}

.block-exhibitor-black {
  font-size: 14pt;
  min-width: 40px;
  color: black;
  font-weight: bold;
  background-color: white;
  text-align: center;
}

.badge-logo {
  display: inline-block;
  max-width: 40px;
  float: right;
}

.badge-attendee-name {
  width: 330px;
  height: 65px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding-right: 30px;
  line-height: 95%;
}


.badge-attendee-organization {
  width: 330px;
  margin-top: 5px;
  height: 35px;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  padding-right: 30px;
}

.badge-attendee-chapter {
  height: 15px;
  font-size: 10pt;
  font-style: italic;
  text-align: right;
}

.badge-block {
  display: inline-block;
  min-height: 43px;
  line-height: 35px;
  width: 11%;
  margin-top: 2px;
  padding: 2px;
  font-size: 12pt;
  font-style: italic;
  text-align: center;
  vertical-align: center;
  background-color: white;
  color: black;
  border: 1px solid grey;

}

.badge-block-outline {
  background-color: white;
}

.badge-block-none {
  background-color: white;
  border: 1px solid white;
}

.badge-attendee-left {
  display: inline-block;
  width: 50%;
  text-align: left;
  height: 20px;
  font-size: 12pt;
}

.badge-attendee-right {
  display: inline-block;
  width: 48%;
  text-align: right;
  height: 20px;
  font-size: 12pt;
}


.badge-attendee-name-small {
  width: 100%;
  margin-top: 6px;
  text-align: right;
  font-size: 8pt;
  font-style: bold;
  color: #202020;
}

.badge-guest-name-small {
  width: 100%;
  margin-top: 6px;
  text-align: left;
  font-size: 8pt;
  font-style: bold;
  color: #202020;
}

.search-dropdown {
  list-style-type: none;
  position: absolute;
  margin-top: 3rem;
  padding-top: 4px;
  padding-left: 10px;
  padding-bottom: 10px;
  background-color: white;
  opacity: 1;
  max-height: 15rem;
  overflow: scroll;
  border-left-color: black;
  border-left-style: solid;
  border-left-width: thin;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-right-color: black;
  border-right-style: solid;
  border-right-width: thin;
  cursor: pointer;
  text-align: left;
  z-index: 99;
}

.search-dropdown>li {
  padding-bottom: 4px;
  padding-left: 10px;
  margin-left: -10px;
}

.matchSelected {
  background-color: #c60000;
  color: white;
}

tbody tr:nth-child(odd) {
  background-color: #eeeeee;
}

tbody tr:nth-child(even) {
  background-color: #dddddd;
}

table {
  background-color: white;
}
