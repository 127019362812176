%usa-list {
  @include u-margin-y(1em);
  line-height: line-height($theme-body-font-family, $theme-body-line-height);
  padding-left: 3ch;

  &:last-child {
    margin-bottom: 0;
  }
}

%usa-list-item {
  margin-bottom: 0.25em;
  max-width: measure($theme-text-measure);

  &:last-child {
    margin-bottom: 0;
  }
}
