@page {
  margin-top: 2mm;
}

.badge-row {
  display: block;
  margin-top: 0mm;
}


.badge {
  width: 104.6mm;
  height: 79.2mm;
  background-color: white;
  border-style: solid;
  border-color: white;
  border-width: thin;
  display: inline-block;
  z-index: 999;
  vertical-align: top;
  background-image: url('./badge-graphic-2024.png');
  background-position: 5mm 8mm;
  background-repeat: no-repeat;
  background-size: 90mm;
}

.badge-preview-scale {
  transform: scale(0.65);
  background-image: url('./badge-graphic-preview-2024.png');
}

.badge-preview {
  border-width: 1px;
  border-color: grey;
  background-color: #55555511;
}

/*   background-image: url('./badge-background.png');
*/
.badge-row-1 {
  min-height: 28mm;
}

.badge-row-2 {
  min-height: 13mm;
  /*	margin-top: -3mm; */
}

.badge-row-3 {
  min-height: 12mm;
}

.badge-row-4 {
  min-height: 12mm;
  max-height: 12mm;
}

.badge-row-5 {
  min-height: 8mm;
}

.badge-row-6 {
  min-height: 10mm;
}

.security {
  background-image: url('./ata-logo-small.png');
  background-size: contain;
  width: 10mm;
  height: 9mm;
  z-index: 99;
}

.security {
  background-image: url('./ata-logo-small.png');
  background-size: 12mm;
  position: relative;
  width: 12mm;
  height: 11mm;
  left: 35mm;
  top: 4mm;
  z-index: 99;
  display: inline-block;
}

.exhibitor {
  font-size: 5mm;
  color: black;
  font-family: "times new roman", serif;
  font-weight: 800;
  position: relative;
  left: 3mm;
  top: 3mm;
  width: 50mm;
  z-index: 998;
  display: inline-block;
  vertical-align: top;
}

.exhibitor-no {
  font-size: 5mm;
  color: white;
  font-family: "times new roman", serif;
  font-weight: 800;
  position: absolute;
  z-index: 998;
}

.exhibitor-no-preview {
  color: #55555500;
}

.first-name {
  width: 101.6mm;
  font-size: 12mm;
  font-family: "times new roman", serif;
  font-weight: 800;
  font-variant-caps: small-caps;
  color: black;
  text-align: center;
  z-index: 99;
}

.title {
  width: 101.6mm;
  font-size: 8mm;
  font-family: "times new roman", serif;
  font-variant-caps: small-caps;
  color: black;
  font-weight: 800;
  margin-top: -5mm;
  text-align: center;
}

.organization {
  width: 101.6mm;
  font-size: 7mm;
  font-family: "times new roman", serif;
  font-variant-caps: normal;

  color: black;
  font-weight: 800;
  text-align: center;
  height: 12mm;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base {
  padding-right: 2mm;
  font-size: 3.5mm;
  font-family: "times new roman", serif;
  font-variant-caps: normal;
  color: black;
  font-weight: 800;
  text-align: right;
}

.fullName {
  width: 97mm;
  padding-right: 2mm;
  font-size: 3mm;
  font-family: "times new roman", serif;
  font-variant-caps: normal;
  color: black;
  font-weight: 800;
  text-align: right;
  float: right;
}

.guest-of {
  padding-left: 5mm;
  width: 95mm;
  font-size: 3mm;
  font-family: "times new roman", serif;
  font-variant-caps: normal;

  color: black;
  font-weight: 800;
  text-align: left;
  float: left;
  margin-top: -5mm;
}
