
.badge-preview {

}

.badge-page-break {
  display: inline-block;
  page-break-after: always;
}

.badge-frame {
  display: inline-block;
  font-family: serif;
  font-size: 2rem;
  color: black;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  width: 530px;
  height: 397px;
}

.badge-header {
  background-image: url("//images/badge-graphic-2021.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 150px;
  margin-top: 10px;
}

.badge-exhibitor {
  margin-top: -0.4rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.badge-security {
  background-image: url("//images/ata-logo-transparent.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  float: right;
  margin-right: 10px;
  background-size: 50px;
  margin-top: -10px;
}

.badge-no-security {

}

.badge-body {
  text-align: center;
}

.badge-go-by-name {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 3.1rem;
  overflow: hidden;
  max-height: 3.1rem;
}

.badge-fullname {
  font-size: 2.5rem;
  line-height: 2.7rem;
  font-weight: bold;
}

.badge-organization-name {
  font-size: 1.8rem;
  font-weight: bold;
  overflow: hidden;
  line-height: 1.9rem;
}

.badge-admin-text {
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.badge-footer {
  margin-top: 0.6rem;
}

.badge-footer-left {
  display: inline-block;
  width: 50%;
}

.badge-footer-left-row-1 {
  font-size: 1rem;
  max-height: 1rem;
}
.badge-footer-left-row-2 {
  font-size: 1rem;
  max-height: 0.8rem;
}

.badge-footer-right {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.badge-footer-right-row-1 {
  font-size: 1rem;
  max-height: 1.2rem;
}

.badge-footer-right-row-2 {
  font-size: 1rem;
  max-height: 0.8rem;
}

@media print {

  .badge-page-header {
    height: 0.95in
  }

  .badge-page-header-single {
    height: 0;
    margin: -0.25in;
  }

  .badge-frame {
    width: 4in;
    height: 3in;
    margin: 0;
    border-width: 0;
  }

  .badge-exhibitor {
    margin-top: -0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }

  .badge-go-by-name {
    margin-top: -0.1in;
    margin-bottom: -0.1in;
    overflow: hidden;
    max-height: 3.0rem;
    font-size: 2.5rem;
  }

  .badge-fullname {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: bold;
  }

  .badge-header {
    background-size: 3.5in;
    height: 1in;
  }

  .badge-organization-name {
    font-size: 1.6rem;
    font-weight: bold;
    overflow: hidden;
    max-height: 1.9rem;
  }

  .badge-footer-left-row-1 {
    overflow: hidden;
    max-height: 1.2rem;
  }
  .badge-footer-left-row-2 {
    overflow: hidden;
    max-height: 1.1rem;
  }

  .badge-footer-right-row-1 {
    overflow: hidden;
    max-height: 1.2rem;
  }

  .badge-footer-right-row-2 {
    overflow: hidden;
    max-height: 1.1rem;
  }

}
